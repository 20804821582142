export default {
    SET_LOYALTYPRODUCTS(state, loyalty_products) {
        state.loyalty_products = loyalty_products
    },
    SET_LOYALTYPRODUCT(state, product) {
        state.loyalty_products.unshift(product)
    },
    UPDATE_LOYALTYPRODUCT(state, product) {
        const prodIndex = state.loyalty_products.findIndex((p) => p.id == product.id)
        Object.assign(state.loyalty_products[prodIndex], product)
    }
}