import axios from '@/axios.js'

export default {
    getLoyaltyProducts({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('pos/rewards')
            .then(response => {
                if(response.data.status) {
                    commit('SET_LOYALTYPRODUCTS', response.data.data)
                    resolve(response.data)
                }
                
            })
            .catch(error => reject(error))
        })
    },
    addReward({commit}, reward) {
        return new Promise((resolve, reject) => {
            axios.put('pos/rewards', reward)
            .then(response => {
                if(response.data.status) {
                    commit('SET_LOYALTYPRODUCT', response.data.data)
                    resolve(response.data)
                }
                reject(response.data.msg)
            })
            .catch(error => reject(error))
        })
    },
    updateReward({commit}, reward) {
        return new Promise((resolve,reject) => {
            axios.post(`pos/rewards/${reward.id}`, reward)
            .then(response => {
                if(response.data.status) {
                    commit('UPDATE_LOYALTYPRODUCT', response.data.data)
                    resolve(response.data)
                }
                reject(response.data.msg)
            })
            .catch(error => reject(error))
        })
    }
}