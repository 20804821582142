<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "Agregar nuevo" : "Actualizar" }} premio</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">

        <!-- Product Image -->
        <template v-if="dataImg">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
            <span class="text-danger text-sm" v-show="errors.has('product_image')">{{ errors.first('product_image') }}</span>
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" name="product_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar imágen</vs-button>
            <!-- <vs-button type="flat" color="#999" @click="dataImg = null">Eliminar imágen</vs-button> -->
          </div>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <!-- NAME -->
        <vs-input label="Nombre" autocomplete="off" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required|max:50'" data-vv-as="Nombre"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- DESCRIPTION -->
        <vs-input v-model="dataDescription" autocomplete="off" label="Descripción" class="mt-5 w-full" name="item-description" v-validate="'required|max:100'" data-vv-as="Descripción"/>
        <span class="text-danger text-sm" v-show="errors.has('item-description')">{{ errors.first('item-description') }}</span>

        <!-- POINTS -->
        <vs-input
          icon-pack="feather"
          icon="icon-dollar-sign"
          label="Puntos"
          v-model="dataPoints"
          autocomplete="off"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, max_value: 99999, min_value: 1 }"
          name="item-points"
          data-vv-as="Puntos"/>
        <span class="text-danger text-sm" v-show="errors.has('item-points')">{{ errors.first('item-points') }}</span>

        <!-- STOCK -->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Stock"
          v-model="dataStock"
          autocomplete="off"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, max_value: 99999, min_value: 0 }"
          name="item-stock"
          data-vv-as="Stock"/>
        <span class="text-danger text-sm" v-show="errors.has('item-stock')">{{ errors.first('item-stock') }}</span>

        <!-- Upload -->
        <!-- <vs-upload text="Upload Image" class="img-upload" ref="fileUpload" /> -->

        <div class="upload-img mt-5" v-if="!dataImg">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Subir imágen</vs-button>
          <vs-alert v-show="image_error" color="danger" class="mt-5" icon-pack="feather" icon="icon-info" style="min-height:62px">{{ image_error }}</vs-alert>
          <!-- <div v-show="image_error" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i><span class="text-danger text-sm">{{ image_error }}</span></div> -->
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, image, name, description, points, stock, status } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataImg = this.$baseUrlImg + image
        this.dataName = name
        this.dataDescription = description
        this.dataPoints = points
        this.dataStock = stock
        this.dataStatus = status
        this.image_loaded = image ? true : false
        this.image_error = ''
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataName: "",
      dataImg: null,
      dataDescription: "",
      dataPoints: 0,
      dataStock: 0,

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      selected: [],

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },

      loading: false
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
        return !this.errors.any() && this.dataName && this.dataDescription && (this.dataPoints > 0) && this.image_loaded && this.image_error == ''
    },

    //console: () => console,
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataName = ""
        this.dataDescription = ""
        this.dataPoints = 0
        this.dataStock = 0
        this.dataImg = null
        this.dataStatus = null
        this.image_loaded = false
        this.image_error = ''
    },
    submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {

            const obj = {
              id: this.dataId,
              name: this.dataName,
              image: (this.image_loaded) ? this.dataImg : "",
              description: this.dataDescription,
              points: this.dataPoints,
              stock: this.dataStock,
              status: this.dataStatus
            }

            if(this.dataId !== null && this.dataId >= 0) {
               this.$store.dispatch("loyalty/updateReward", obj)
               .then(()=> {
                  this.submitSuccess()
                })
               .catch(err => { this.submitError(err) })
            }else{
              delete obj.id
              this.$store.dispatch("loyalty/addReward", obj)
              .then(() => this.submitSuccess())
              .catch(err => { this.submitError(err) })
            }
          }
        })

    },
    submitSuccess() {
      this.showAddSuccess()
      this.enableSaveButton()
      this.initValues()
      this.$emit('closeSidebar')
      this.$emit("reloadTable")
    },
    submitError(msg) {
      this.enableSaveButton()
      this.showAddError(msg)
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Premio',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    showAddError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Premio',
        text: msg || 'Error al guardar los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: 'primary',
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.product_image.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.product_image.width = img.width;
            this.product_image.height = img.height;
            // console.log(this.product_image.size);
            if (this.product_image.width != this.product_image.height || this.product_image.width > 300 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px';
              // console.log(this.image_error);
            } else {
              if (this.product_image.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImg = e.target.result
                this.image_loaded = true;
              }
            }
          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
