import state from './moduleLoyaltyState.js'
import actions from './moduleLoyaltyActions.js'
import getters from './moduleLoyaltyGetters.js'
import mutations from './moduleLoyaltyMutations.js'

export default {
    isRegistered: false,
    namespaced: true,
    state: state,
    actions: actions,
    mutations: mutations,
    getters: getters
}